import React from 'react'
import './DocumentCard.scss'
import {faArrowCircleDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const DocumentCard = ({
                          title,
                          text,
                          href = ''
                      }) => (
    <div className="DocumentCard">
        <div className="DocumentCard--Content flex flex-col justify-between">
            {title && <h3 className="DocumentCard--Title">{title}</h3>}
            {text && <div className="DocumentCard--Text">{text}</div>}
            <a href={href}>
                <button className="Button download-button">Télécharger <FontAwesomeIcon icon={faArrowCircleDown}/>
                </button>
            </a>
        </div>
    </div>
);

export default DocumentCard
